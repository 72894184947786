import React from 'react';
import QRCode from 'qrcode.react';
import { Layout, Row, Col, Typography, Button } from 'antd';
import '../styles/payment-successful.scss';
import 'antd/dist/antd.css';

const { Content } = Layout;

const { Title, Text, Paragraph } = Typography;

const PaySuccessful = () => {

  return (
    <Layout className="content-pay-successful">
      <Content className="content-layout-content">
        <Row className="row-config" justify="center" align="middle">
          <Title className="text-payment" level={4}>Felicitaciones</Title>
          <Col span={20}>
            <p className="text-pay-sub">Tu compra fue concretada</p>
          </Col>
        </Row>
        <Row>
          <Col className="img-code-bar">
            <Text strong className="text-code-bar">CONTRATO</Text>
            <QRCode
              className="code-bar"
              fgColor="#2699FB"
              bgColor="#F1F9FF"
              value="http://facebook.github.io/react/"/>
            <Text strong className="text-code-bar">#12342SDF34</Text>
          </Col>
        </Row>
        <Row className="row-config" justify="center">
          <Col span={20}>
            <Paragraph className="text-payment">
              Tu cobertura dura <Text strong>desde</Text> las 1.45pm del 27 de Jun
              2020 <Text strong>hasta</Text> la 1.45pm del 28 Jun 2020.
              La información fue enviada al correo del <Text strong>TITULAR</Text> y del  <Text strong>BENEFICIARIO</Text>.
            </Paragraph>
            <Text className="text-payment">Estas protegido con nosotros</Text>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={10}>
            <Button size="large" type="primary" href="/travel" block>VER RUTA</Button>
          </Col>
          <Col span={10}>
            <Button size="large" href="/" block>SALIR</Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PaySuccessful;
